module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.25.0_gatsby@4.25.4_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Flaminem - website","short_name":"Flaminem","start_url":"/","background_color":"#5d47d5","theme_color":"white","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fa987b24c15ad2fca4058ce31c17d757"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@5.25.0_gatsby@4.25.4_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-intl@3.0.2_gatsby@4.25.4_react@17.0.2_typescript@4.9.4/node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/flaminem/flaminem-corporate-website/packages/frontend/src/intl","languages":["fr","en"],"defaultLanguage":"fr","redirect":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@4.25.0_gatsby@4.25.4_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PVQKLMHC","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.25.4_babel-eslint@10.1.0_react-dom@17.0.2_react@17.0.2_typescript@4.9.4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
