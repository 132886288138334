import * as React from 'react';
const graph = require('../graph/kyc-graph.mjs');
import { faSync, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { graphql, useStaticQuery } from 'gatsby';
import { NNU, noop } from '../helpers';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GatsbyImage } from 'gatsby-plugin-image';
import { GraphQuery } from '../../graphqlTypes';
import Layout from '../components/layout';
import { library } from '@fortawesome/fontawesome-svg-core';
import { motion } from 'framer-motion';
import Navbar from '../components/navbar';
import ReactMarkdown from 'react-markdown';
import SEO from '../components/seo';
import SmallFooter from '../components/smallFooter';
import { useIntl } from 'gatsby-plugin-react-intl';

import './graph.scss';
import { Customer, GraphStakeholder, SelectionOfStakeholderProps, Stakeholder } from "../@types/kyc-graph";
import { getDefaultLanguageText } from "../graph/languageTexts";

library.add(fas);

const GraphWrapper = () => {
  const intl = useIntl();
  const [selectedStakeholder, setSelectedStakeholder] = React.useState<GraphStakeholder | null>(null);
  const selectionOfStakeholderRequested = React.useCallback((item: SelectionOfStakeholderProps<GraphStakeholder> | null) => setSelectedStakeholder(item && item.stakeholder), []);

  const [renderUnidentifiedBeneficiaryOwners, setRenderUnidentifiedBeneficiaryOwners] = React.useState(false);
  const [isManualPositioning, setIsManualPositioning] = React.useState(false);
  const [reflow, setReflowHandlder] = React.useState(() => noop);

  const stakeholders: Stakeholder[] = React.useMemo(
    () => [
      {
        id: 'zoe',
        name: 'Zoe MARIE',
        typeName: intl.formatMessage({ id: 'graph.stakeholder.person' }),
        type: 'person',
        subsidiaries: null,
        iconClassname: 'fas fa-user',
        ownCustomerFolderId: null,
        shareholding: null,
        legalRepresentatives: null,
        relatedEntities: null,
        completionStatus: {
          requiredFields: true,
          eReputation: true,
          supportingDocuments: {
            allCompleted: true,
            someExpired: false
          }
        }
      },
      {
        id: 'emmanuel',
        name: 'Emmanuel MACRON',
        typeName: intl.formatMessage({ id: 'graph.stakeholder.person' }),
        type: 'person',
        subsidiaries: null,
        iconClassname: 'fas fa-user',
        ownCustomerFolderId: null,
        shareholding: null,
        legalRepresentatives: null,
        relatedEntities: null,
        completionStatus: {
          requiredFields: true,
          eReputation: true,
          supportingDocuments: {
            allCompleted: false,
            someExpired: false
          }
        }
      },
      {
        id: 'detachprim',
        name: "DETACHPRIM'",
        typeName: intl.formatMessage({ id: 'graph.stakeholder.corporation' }),
        type: 'entity',
        subsidiaries: null,
        iconClassname: 'fas fa-building',
        ownCustomerFolderId: null,
        shareholding: {
          completion: {
            type: 'required',
            value: 1,
            customVotingRights: null
          },
          shareholders: [
            {
              id: 'sdm',
              share: 1
            }
          ]
        },
        legalRepresentatives: null,
        relatedEntities: null,
        completionStatus: {
          requiredFields: true,
          eReputation: false,
          supportingDocuments: {
            allCompleted: true,
            someExpired: false
          }
        }
      },
      {
        id: 'juliet',
        type: 'person',
        name: 'Juliet CARMINA',
        typeName: intl.formatMessage({ id: 'graph.stakeholder.person' }),
        iconClassname: null,
        subsidiaries: null,
        ownCustomerFolderId: null,
        shareholding: null,
        legalRepresentatives: null,
        relatedEntities: null,
        completionStatus: {
          requiredFields: true,
          eReputation: true,
          supportingDocuments: {
            allCompleted: true,
            someExpired: false
          }
        }
      },
      {
        id: 'sdm',
        name: 'SDM Financing',
        typeName: intl.formatMessage({ id: 'graph.stakeholder.corporation' }),
        type: 'entity',
        iconClassname: 'fas fa-building',
        ownCustomerFolderId: null,
        subsidiaries: null,
        shareholding: {
          completion: {
            type: 'required',
            value: 1,
            customVotingRights: null
          },
          shareholders: [
            {
              id: 'juliet',
              share: 0.1,
              customVotingRights: 0
            },
            {
              id: 'emmanuel',
              share: 0,
              customVotingRights: 1
            }
          ]
        },
        legalRepresentatives: null,
        relatedEntities: null,
        completionStatus: {
          requiredFields: true,
          eReputation: true,
          supportingDocuments: {
            allCompleted: false,
            someExpired: false
          }
        }
      }
    ],
    [intl]
  );

  const customer: Customer = React.useMemo(
    () => ({
      id: 'Customer',
      name: 'INTERCO',
      type: 'entity',
      typeName: intl.formatMessage({ id: 'graph.stakeholder.customer' }),
      iconClassname: null,
      legalRepresentatives: [{ id: 'emmanuel' }],
      relatedEntities: null,
      subsidiaries: null,
      completionStatus: {
        requiredFields: true,
        eReputation: true,
        supportingDocuments: {
          allCompleted: true,
          someExpired: false
        }
      },
      effectiveStakeholders: [
        {
          id: 'zoe',
          effectiveShare: null,
          beneficiaryOwner: true
        }
      ],
      thirdPartyEntities: [],
      shareholding: {
        completion: {
          type: 'required',
          value: 1,
          customVotingRights: null
        },
        shareholders: [
          {
            id: 'zoe',
            share: 0.25
          },
          {
            id: 'detachprim',
            share: 0.75
          }
        ]
      }
    }),
    [intl]
  );


  const getLanguageText = React.useCallback(
    (key: any) => {
      if (intl.locale === 'fr') {
        return getDefaultLanguageText(key);
      }

      return intl.formatMessage({ id: key });
    },
    [intl]
  );

  return (
    <div className='graph-standalone-wrapper'>
      <p className='graph-tools'>
        <span className='graph-unidentified-bo' onClick={() => setRenderUnidentifiedBeneficiaryOwners(s => !s)}>
          <FontAwesomeIcon icon={renderUnidentifiedBeneficiaryOwners ? faToggleOn : faToggleOff} />
          <span className='graph-tools--text'>{intl.formatMessage({ id: 'graph.renderUnidentifiedUBO' })}</span>
        </span>
        <FontAwesomeIcon icon={faSync} onClick={() => (reflow(), setIsManualPositioning(false))} />
        <span className='graph-tools--text'>{getLanguageText(isManualPositioning ? 'graph.text.manual_positioning' : 'graph.text.automatic_positioning')}</span>
      </p>
      <graph.StakeholderGraph
        customer={customer}
        stakeholders={stakeholders}
        renderGlobalCompletionStatus={true}
        computeShareholding={true}
        beneficiaryOwnerThreshold={0.25}
        renderUnidentifiedBeneficiaryOwners={renderUnidentifiedBeneficiaryOwners}
        selectedStakeholder={selectedStakeholder}
        selectionOfStakeholderRequested={selectionOfStakeholderRequested}
        registerReflowHandler={(handler: any) => setReflowHandlder(() => handler)}
        savingOfStakeholderNodePositionsRequested={() => setIsManualPositioning(true)}
        getLanguageText={getLanguageText}
        className='graph-container'
      />
    </div>
  );
};

export default function Graph() {
  const intl = useIntl();
  const data = useStaticQuery<GraphQuery>(graphql`
    query GRAPH {
      allStrapiGraphPage {
        edges {
          node {
            locale
            title
            description
            features
            useCaseTitle
            useCaseBulletPoints
            forWhoTitle
            forWhoBulletPoints
            staticImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `);
  const graphPageData = NNU(data.allStrapiGraphPage.edges.find(e => e.node.locale === intl.locale)).node;

  const [renderGraphComponent, setRenderGraphComponent] = React.useState(false);

  React.useEffect(() => {
    const resizeHandler = () => setRenderGraphComponent(window.matchMedia('(min-width: 768px)').matches);

    window.addEventListener('resize', resizeHandler);
    resizeHandler();
  }, []);

  return (
    <Layout navBar={() => <Navbar className='navbar-small' scrolled={true} />} Footer={SmallFooter}>
      <SEO title={NNU(graphPageData.title)} lang={intl.locale} />
      <div className='graph-page'>
        <div className='graph-desc-heading'>
          <motion.h1
            className='graph-desc-title'
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.6
            }}
          >
            {graphPageData.title}
          </motion.h1>
          <motion.div
            className='graph-desc-sub-title'
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              delay: 0.3,
              duration: 0.6
            }}
          >
            <ReactMarkdown>{graphPageData.description || ''}</ReactMarkdown>
          </motion.div>
        </div>
        <div className='graph-desc-container'>
          <div className='graph-desc-features-container'>
            <motion.div
              className='graph-features'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                delay: 0.6,
                duration: 0.6
              }}
            >
              <ReactMarkdown className='description'>{graphPageData.features || ''}</ReactMarkdown>
            </motion.div>
          </div>
          <div className='graph-desc-usecases-forwho-container'>
            {[
              { title: graphPageData.useCaseTitle, bulletPoints: graphPageData.useCaseBulletPoints },
              { title: graphPageData.forWhoTitle, bulletPoints: graphPageData.forWhoBulletPoints }
            ].map((sectionData, i) => (
              <div className='graph-desc-usecases-forwho' key={i}>
                <motion.h2
                  className='graph-desc-title'
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    delay: 0.4,
                    duration: 0.8
                  }}
                >
                  {sectionData.title}
                </motion.h2>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    delay: 0.8 + i * 0.2,
                    duration: 0.8
                  }}
                >
                  <ReactMarkdown className='description'>{sectionData.bulletPoints || ''}</ReactMarkdown>
                </motion.div>
              </div>
            ))}
          </div>
        </div>
        {renderGraphComponent && <GraphWrapper />}
        {!renderGraphComponent && (
          <GatsbyImage
            image={NNU(graphPageData.staticImage?.localFile?.childImageSharp?.gatsbyImageData)}
            className='graph-image'
            alt='graph-image'
            imgStyle={{ objectFit: 'contain' }}
          />
        )}
      </div>
    </Layout>
  );
}
